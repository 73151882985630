
import { defineComponent, onMounted, ref } from "vue";




import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import AccountDetails_ECC from "@/components/dashboard/AccountDetails_ECC.vue"
import AccountDetailsWidget_ECC from "@/components/dashboard/AccountDetailsWidget_ECC.vue";

import { getCurrentUser } from "@/utils/index"
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { getPayoutDates, getECCMaturityMonitor } from "@/api/ecc"
    

export default defineComponent({
  name: "dashboarddtc",
  components: {
   /* AccountDetails_ECC, */
    AccountDetailsWidget_ECC
},
  data() {
    return {
      user: {},
      pt : "",
      ccode : -1,
      slotdata : {},
      modalkey : 0,
      payoutDates : {},
      loadingDates : false,
      daysLeft : 0,
      series : [0.15],
      loadingMonitor : false,
      eccmaturitymonitor: {},
      eccpackagetype : "",
      interestRate : 0,
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });

    

    const baseColor = getCSSVariableValue("--bs-primary");
    const lightColor = getCSSVariableValue("--bs-light-primary");
    const labelColor = getCSSVariableValue("--bs-" + "gray-700");

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        height: 200,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColor,
              fontSize: "30px",
              fontWeight: "700",
              offsetY: 12,
              show: true,
              formatter: function(val) {
                return val + "%";
              }
            }
          },
          track: {
            background: lightColor,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColor],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };

   

    return {
      chartOptions
    };


  }, 
  
  methods: {
      
    async getECCPayoutDates(){
      this.loadingDates = true;
      let param = {"x" : "x"}
      const response = await getPayoutDates(param);
      this.payoutDates = response.data.payoutDates;
      this.loadingDates = false;
    },

    async getECCMaturity_Monitor(){
      this.loadingMonitor = true;

      var self = this;

      let param = {"x" : "x"}
      const response = await getECCMaturityMonitor(param);

      this.eccmaturitymonitor = response.data.eccmaturitymonitor;
      this.series = [response.data.eccmaturitymonitor.porsyento];

      this.eccpackagetype = response.data.eccmaturitymonitor.eccpackagetype;
      this.interestRate = response.data.eccmaturitymonitor.interestrate;


      this.loadingMonitor = false;
    }


  },
  mounted(){
    
        this.user = getCurrentUser();
        this.pt = String(localStorage.getItem("pt"));
        this.getECCPayoutDates();
        this.getECCMaturity_Monitor();

        if(localStorage.getItem("pt") === 'ovi'){
          this.$router.push('/dashboard/reminderspage') ;
        } else if(localStorage.getItem("pt") === 'ecp' || localStorage.getItem("pt") === 'edp'){
          this.$router.push('/dashboardecp/reminderspage') ;
        } 

  },
});
