import request from '@/utils/request'


export function getPayoutDates(param) {
  return request({ url: 'vgetpayoutdates', method: 'post', data: param })
}  



export function getECCBalanceAndIncome(param) {
  return request({ url: 'vgeteccbalanceandincome', method: 'post', data: param })
}  


export function getECCMaturityMonitor(param) {
  return request({ url: 'vgeteccmaturitymonitor', method: 'post', data: param })
}  

