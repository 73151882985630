
import { defineComponent } from "vue";
import { getCurrentUser, setCurrentUser} from "@/utils/index"
import { getECCBalanceAndIncome } from "@/api/ecc"
import AccountList from "@/components/dashboard/AccountList.vue"

export default defineComponent({
  name: "widget-1",
  components: {
    AccountList
  },
  data() {
    return {
      currentUsid: "",
      user: "",
      eccdashboarddata: {},
      loadingBalance: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      pt : ""
    }
  },
  methods: {
    async getDashboardData_ECC() {
      this.loadingBalance = true;
      let param = {"x" : "x"}
      const response = await getECCBalanceAndIncome(param);
      console.log(response.data.eccbalanceincome);
      this.eccdashboarddata = response.data.eccbalanceincome;
      this.loadingBalance = false;
    },
    onResize() {
      //this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      console.log(this.windowWidth);
      if(this.windowWidth <= 450){
        
      } else {
        
      }
    }

    
  },
  mounted() {

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });

      this.user = getCurrentUser();
      this.currentUsid = this.user;
      this.getDashboardData_ECC(); 
      this.pt = String(localStorage.getItem("pt"));
  },
  props: {
    widgetClasses: String,
    widgetColor: String,
    chartHeight: Number
  },
  setup() {

  }
});
